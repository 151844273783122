<template>
    <dialog-confirmation
        v-model="dialogOpen"
        :hide-validate-btn="true"
        cancel-label="Fermer"
        title="Annotation des bases de données"
        max-width="800"
        width="800"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Informations sur la protéine</p>
                    <element-row label="HGVS.p">
                        <span :class="$get(item, 'vep_annotation.hgvsp', 'NA') === 'NA' ? 'text--disabled' : ''">
                            {{ $get(item, 'vep_annotation.hgvsp', 'NA').after(':') }}
                        </span>
                    </element-row>
                    <element-row label="Transcrit">{{ $get(item, 'transcript.transcript_id') }}</element-row>
                    <element-row label="Gène">{{ $get(item, 'gene.name') }}</element-row>
                    <element-row label="Numéro d'accès Uniprot">
                        <a
                            v-for="(uniprot_entry, i) in uniq($get(item, 'vep_annotation.uniprot_acc', []))"
                            :key="i"
                            :href="`https://www.uniprot.org/uniprot/${uniprot_entry}`"
                            target="_blank"
                            class="text-decoration-none"
                        >
                            {{ uniprot_entry }}<br>
                        </a>
                    </element-row>
                    <element-row label="Prédiction SIFT">
                        <span
                            :class="$get(item, 'vep_annotation.sift_prediction', 'NA') === 'NA' ? 'text--disabled' : ''"
                        >
                            {{ $get(item, 'vep_annotation.sift_prediction', 'NA') }}
                        </span>
                    </element-row>
                    <element-row label="Prédiction Polyphen">
                        <span
                            :class="$get(item, 'vep_annotation.polyphen_prediction', 'NA') === 'NA' ? 'text--disabled' : ''"
                        >
                            {{ $get(item, 'vep_annotation.polyphen_prediction', 'NA') }}
                        </span>
                    </element-row>
                    <element-row label="Prédiction FATHMM">
                        <span
                            :class="$get(item, 'vep_annotation.fathmm-mkl_coding_pred', 'NA') === 'NA' ? 'text--disabled' : ''"
                        >
                            {{ $get(item, 'vep_annotation.fathmm-mkl_coding_pred', 'NA') }}
                        </span>
                    </element-row>
                </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Variant</p>
                    <element-row label="dbSNP">
                        <a v-if="$get(item, 'vep_annotation.rs_dbsnp', 'NA') !== 'NA'"
                           class="text-decoration-none"
                           :href="`https://www.ncbi.nlm.nih.gov/snp/${$get(item, 'vep_annotation.rs_dbsnp')}`"
                           target="_blank">{{ $get(item, 'vep_annotation.rs_dbsnp') }}</a>
                        <div v-else class="text--disabled">NA</div>
                    </element-row>
                    <element-row label="ClinVar">
                        <div v-if="$get(item, 'vep_annotation.clinvar_id', 'NA') !== 'NA'">
                            <a :href="`https://www.ncbi.nlm.nih.gov/clinvar/variation/${$get(item, 'vep_annotation.clinvar_id')}`"
                               class="text-decoration-none"
                               target="_blank">{{ $get(item, 'vep_annotation.clinvar_id') }} -
                                {{ $get(item, 'vep_annotation.clinvar_clnsig') }}</a>
                        </div>
                        <div v-else class="text--disabled">NA</div>
                    </element-row>
                    <element-row label="Fréquence de population (gnomAD)">
                        <span :class="gnomAD(item) === 'NA' ? 'text--disabled' : ''">
                        {{ gnomAD(item) }}
                        </span>
                    </element-row>
                    <element-row v-if="item.gene.name === 'TP53' && item.iarc_annotations.length > 0" label="IARC">
                        {{ firstOf(item.iarc_annotations).transactivation_class }}
<!--                        <a href="https://tp53.isb-cgc.org/search_gene_by_var" target="_blank">https://tp53.isb-cgc.org/search_gene_by_var</a>-->
                    </element-row>
                </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Gène</p>
                    <element-row label="OncoKB">
                        <a :href="`https://www.oncokb.org/gene/${item.gene.name}`" target="_blank"
                           class="text-decoration-none">{{ item.gene.name }}</a>
                    </element-row>
                    <element-row label="Cosmic">
                        <a :href="`https://cancer.sanger.ac.uk/cosmic/gene/analysis?ln=${item.gene.name}`"
                           target="_blank"
                           class="text-decoration-none">{{ item.gene.name }}</a>
                    </element-row>
                    <element-row label="Variants à la même position">
                        <a v-for="(colocated_variant, i) in $get(item, 'vep_annotation.colocated_variants', [])"
                           :key="colocated_variant.id"
                           :href="`https://cancer.sanger.ac.uk/cosmic/search?q=${colocated_variant.id}`"
                           target="_blank"
                           class="text-decoration-none"
                        >
                            {{ colocated_variant.id }}<br>
                        </a>
                    </element-row>
                </v-col>
            </v-row>
            <v-row v-if="!isEmpty(item.publications)">
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Publications
                        <v-chip small>{{ item.publications.length }}</v-chip>
                        <v-btn icon class="ml-1" color="primary" @click="isPublicationsShown=!isPublicationsShown">
                            <v-icon>{{ isPublicationsShown ? 'mdi-chevron-right' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </p>
                    <div v-if="isPublicationsShown">
                        <v-row v-for="annotation in item.publications" :key="`pubmed-${annotation.id}`" dense>
                            <v-col cols="12">
                                <element-row>
                                    <template #label>
                                        <v-btn
                                            link
                                            color="primary"
                                            text
                                            small
                                            block
                                            class="ml-1"
                                            :href="`https://pubmed.ncbi.nlm.nih.gov/${$get(annotation, 'publication.content.uid')}`"
                                            target="_blank">
                                            {{ $get(annotation, 'publication.content.uid') }}
                                            <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="!readonly"
                                            color="error"
                                            small
                                            block
                                            depressed
                                            outlined
                                            @click="removePublication(annotation.id)"
                                        >
                                            Supprimer
                                        </v-btn>
                                    </template>
                                    <p><span class="font-weight-medium">
                                        {{ $get(annotation, 'publication.content.title') }}
                                        </span>
                                        <v-chip x-small
                                                v-if="areMatchingAnnotations(annotation.tumor_type_id, tumorTypeId)"
                                                class="ml-1" label color="success">
                                            Compatible
                                        </v-chip>
                                    </p>
                                    <p><span class="text--disabled">Commentaire:
                                        {{ $get(annotation, 'evidence') }}
                                        </span>
                                    </p>
                                    <p class="text-small text-caption text-right">Ajouté par
                                        {{ $get(annotation, 'user.name') }}</p>
                                </element-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        outlined
                        class="mt-2"
                        link
                        :href="link"
                        target="_blank"
                        block
                        color="primary"
                    >
                        Rechercher dans PubMed
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        outlined
                        class="mb-2"
                        @click="publicationDialogOpen = true"
                        block
                        color="primary"
                    >
                        Ajouter une publication
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <add-publication-dialog
            v-if="!readonly"
            v-model="publicationDialogOpen"
            :tumor-type-id="tumorTypeId"
            :item-id="$get(item, 'id', null)"
            @publication-added="reloadAnnotations"
        ></add-publication-dialog>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {first, isEmpty, uniq} from 'lodash'
import get from "lodash/get";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import AddPublicationDialog from "@/Components/domain-specific/ngs/dialogs/AddPublicationDialog.vue";
import {Variant} from "@/models";

@Component({
    components: {AddPublicationDialog, ElementRow, DialogConfirmation}
})
export default class NgsDatabaseAnnotationDialog extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop() readonly item!: Variant
    @Prop() readonly tumorTypeId!: null | number
    @Prop() readonly !: boolean

    publicationDialogOpen: boolean = false
    isPublicationsShown: boolean = false

    translationTable: { [key: string]: string } = {
        Ala: 'A',
        Arg: 'R',
        Asn: 'N',
        Asp: 'D',
        Asx: 'B',
        Cys: 'C',
        Glu: 'E',
        Gln: 'Q',
        Glx: 'Z',
        Gly: 'G',
        His: 'H',
        Ile: 'I',
        Leu: 'L',
        Lys: 'K',
        Met: 'M',
        Phe: 'F',
        Pro: 'P',
        Ser: 'S',
        Thr: 'T',
        Trp: 'W',
        Tyr: 'Y',
        Val: 'V',
        Ter: '*',
    }

    gnomAD(item: any) {
        if (get(item, 'vep_annotation.gnomad_exomes_af', 'NA') !== 'NA') {
            return `${Number(get(item, 'vep_annotation.gnomad_exomes_af') * 100)}%`
        }
        return 'NA'
    }

    uniq(list: string[]) {
        return uniq(list)
    }

    firstOf(list: any[]) {
        return first(list)
    }

    isEmpty(value?: any) {
        return isEmpty(value)
    }

    removePublication(publicationId: number) {
        this.$inertia.delete(
            route('ngs_publications.destroy', {
                ngs_publication: publicationId
            }),
            {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit('publication-added')
                    this.$toast('Publication supprimée', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }

    reloadAnnotations() {
        this.$emit('publication-added')
    }

    areMatchingAnnotations(alterationId: number, annotationAlterationId: number): boolean {
        return alterationId === annotationAlterationId
    }

    get link(): string {
        const gene = this.item.gene.name
        let pattern = this.item.vep_annotation.hgvsc.after(':c.')

        if (this.item.vep_annotation.hgvsp !== 'NA') {
            let hgvsp = this.item.vep_annotation.hgvsp.after(':p.')
            if (this.item.vep_annotation.hgvsc.includes('del') && !this.item.vep_annotation.hgvsc.includes('delins')) {
                hgvsp = hgvsp.substring(0, hgvsp.indexOf("fs"))
            }
            const shortHgvsp = hgvsp.replace(/[A-Z][a-z]{2}/g, (string, key) => {
                return this.translationTable[string] || string
            })
            pattern = `(${hgvsp}%20OR%20${shortHgvsp})`
        }


        return `https://pubmed.ncbi.nlm.nih.gov/?term=${gene}%20AND%20${pattern}`
    }
}
</script>

<style lang="scss" scoped>

</style>
